import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="customer-product"
export default class extends Controller {
  static targets = ['monthlyField',
                    'rentedField',
                    'refilledField',
                    'destroyFlag',
                    'priceAndQtyInputContainer',
                    'priceAndQtyInput',
                    'monthlyPriceAndQtyInput',
                    'price',
                    'quantity',
                    'monthlyQuantity',
                    'monthlyPrice',
                    'monthlyLabel',
                    'regularLabel',
                    'regularButton',
                    'weekdaysButton',
                    'monthlyButton',
                    'rentedButton',
                    'refilledButton',
                    'isCustomerProduct',
                    'divHideAble',
                    'upArrow',
                    'downArrow',
                    'dayOfWeek',
                    'recurringContainer',
                    'dayOfWeekAlert',
                    'unitPriceLabel',
                    'monthlyPriceLabel',
                    'monthlyBtn']
  connect() {
    this.regularButtonTargets.forEach(el => {
      if(el.checked) {
        this.isRegular({ target: { dataset: { targetId: el.dataset.targetId, initialChecked: el.dataset.initialChecked, gstPercentage: el.dataset.gstPercentage, gstEnabled: el.dataset.gstEnabled} } });
      }
    })

    this.monthlyButtonTargets.forEach(el => {
      if(el.checked) {
        this.isMonthly({ target: { dataset: { targetId: el.dataset.targetId, initialChecked: el.dataset.initialChecked, gstPercentage: el.dataset.gstPercentage, gstEnabled: el.dataset.gstEnabled } } });
      }
    })

    this.rentedButtonTargets.forEach(el => {
      if(el.checked) {
        this.isRented({ target: { dataset: { targetId: el.dataset.targetId, initialChecked: el.dataset.initialChecked, gstPercentage: el.dataset.gstPercentage, gstEnabled: el.dataset.gstEnabled } } });
      }
    })
  }

  isRegular(event) {
    let targetId = event.target.dataset.targetId;
    this.buildRegularFormAttribute(targetId, event);
    this.hideMonthlyQtyAndPriceContainer(targetId);
    this.showQtyAndPriceContainer(targetId);
    this.hideMonthlyLabel(targetId);
    this.showRegularLabel(targetId, event);
    this.updateRegularInitialValue(targetId, event);
    this.showRecurringContainer(targetId)
    this.updateIsMonthlyDataAttr(targetId, false)
    this.showProductPrice(targetId, 'unitPrice', parseFloat(event.target.dataset.gstPercentage), event.target.dataset.gstEnabled);
    this.showRefilledCheckBoxAndSetInitialValue(targetId);
  }

  isMonthly(event){
    let targetId = event.target.dataset.targetId;
    this.buildMonthlyFormAttribute(targetId);
    this.showMonthlyQtyAndPriceContainer(targetId);
    this.showQtyAndPriceContainer(targetId);
    this.showMonthlyLabel(targetId, event);
    this.hideRegularLabel(targetId);
    this.updateMonthlyInitialValue(targetId, event);
    this.showRecurringContainer(targetId)
    this.updateIsMonthlyDataAttr(targetId, true)
    this.showProductPrice(targetId, 'unitPrice', parseFloat(event.target.dataset.gstPercentage), event.target.dataset.gstEnabled)
    this.showProductPrice(targetId, 'monthlyPrice', parseFloat(event.target.dataset.gstPercentage), event.target.dataset.gstEnabled, 'true')
    this.showRefilledCheckBoxAndSetInitialValue(targetId);
  }

  isRented(event){
    let targetId = event.target.dataset.targetId;
    this.buildRentedFormAttribute(targetId);
    this.hideQtyAndPriceContainer(targetId);
    this.showMonthlyQtyAndPriceContainer(targetId);
    this.hideMonthlyLabel(targetId);
    this.hideRegularLabel(targetId);
    this.updateRentedInitialValue(targetId, event);
    this.hideRecurringContainer(targetId)
    this.updateIsMonthlyDataAttr(targetId, false)
    this.showProductPrice(targetId, 'monthlyPrice', parseFloat(event.target.dataset.gstPercentage), event.target.dataset.gstEnabled)
    this.hideRefilledCheckBoxAndSetToFalse(targetId)
  }

  isDestroyed(event){
    let customerProductId = event.target.dataset.customerProductId.trim();
    let productId = event.target.dataset.productId.trim();
    this.updateIsCustomerProductFlag(event, productId);
    if (customerProductId.length !=0 ) {
      if (event.target.checked==true) {
        this.changeDestroyFieldValue(productId, "false");
      } else {
        this.changeDestroyFieldValue(productId, "true");
      }
    }
  }

  isRefilled(event){
    let targetId = event.target.dataset.targetId;
    let targets = this.refilledFieldTargets;
    let value = event.target.checked;
    this.updateFiledValue(targets, targetId, value)
  }

  hideRefilledCheckBoxAndSetToFalse(targetId) {
    this.updateFiledValue(this.refilledFieldTargets, targetId, false);
    this.addAttribute(this.refilledButtonTargets, targetId);
  }

  showRefilledCheckBoxAndSetInitialValue(targetId) {
    this.removeAttributeAndUpdateValue(this.refilledButtonTargets, targetId);
  }

  showRecurringContainer(targetId){
    let targetContainers =  this.recurringContainerTargets;
    targetContainers.forEach(el => {
      if(el.dataset.targetId == targetId) {
        el.classList.remove("hidden");
      }
    })
  }

  hideRecurringContainer(targetId){
    let targetContainers =  this.recurringContainerTargets;
    targetContainers.forEach(el => {
      if(el.dataset.targetId == targetId) {
        el.classList.add("hidden");
      }
    })
  }

  toggleFlexClass(targetId, classToBeAdded, classToBeRemoved) {
    let targetContainers = this.priceAndQtyInputContainerTargets;
    targetContainers.forEach(el => {
      if(el.dataset.targetId == targetId) {
        el.classList.remove(classToBeRemoved);
        el.classList.add(classToBeAdded);
      }
    })
  }

  buildRegularFormAttribute(targetId, event) {
    this.changeMonthFieldValue(targetId, "false");
    this.changeRentedFieldValue(targetId, "false");
    this.toggleFlexClass(targetId, 'flex-col', 'flex-col-reverse');
    this.setMonthlyPriceANdQuantityValue(targetId, event);
  }

  buildMonthlyFormAttribute(targetId) {
    this.changeMonthFieldValue(targetId, "true");
    this.changeRentedFieldValue(targetId, "false")
    this.toggleFlexClass(targetId ,'flex-col-reverse', 'flex-col');
  }

  buildRentedFormAttribute(targetId) {
    this.changeMonthFieldValue(targetId, "false");
    this.changeRentedFieldValue(targetId, "true");
    this.toggleFlexClass(targetId, 'flex-col', 'flex-col-reverse');
    this.setPriceAndQuantityToZero(targetId);
  }

  changeMonthFieldValue(targetId, value){
    let monthFieldTarget = this.monthlyFieldTargets;
    this.updateFiledValue(monthFieldTarget, targetId, value);
  }

  changeRentedFieldValue(targetId, value){
    let rentedFieldTarget = this.rentedFieldTargets;
    this.updateFiledValue(rentedFieldTarget, targetId, value);
  }

  changeDestroyFieldValue(targetId, value){
    let destroyFlagTarget = this.destroyFlagTargets;
    this.updateFiledValue(destroyFlagTarget, targetId, value);
  }

  setMonthlyPriceANdQuantityValue(targetId, event) {
    const monthlyPriceTargets = this.monthlyPriceTargets;
    this.updateFiledValue(monthlyPriceTargets, targetId, 0);

    const monthlyQtyTargets = this.monthlyQuantityTargets;
    this.updateFiledValue(monthlyQtyTargets, targetId, 0);
  }

  setPriceAndQuantityToZero(targetId) {
    const priceTargets = this.priceTargets;
    this.updateFiledValue(priceTargets, targetId, 0);

    const qtyTargets = this.quantityTargets;
    this.updateFiledValue(qtyTargets, targetId, 0);
  }

  updateIsCustomerProductFlag(event, productId) {
    const isCustomerProductTarget = this.isCustomerProductTargets;
    let value = event.target.checked ? true: false;
    this.updateFiledValue(isCustomerProductTarget, productId, value)
  }

  updateRegularInitialValue(targetId, event) {
    const initialChecked = event.target.dataset.initialChecked;
    const priceTargets = this.priceTargets;
    const qtyTargets = this.quantityTargets;
    this.updateInitialValue(priceTargets, targetId);
    if (initialChecked == "true") {
      this.updateInitialValue(qtyTargets, targetId);
    } else {
      this.updateFiledValue(qtyTargets, targetId, 0);
    }
  }

  updateMonthlyInitialValue(targetId, event) {
    const initialChecked = event.target.dataset.initialChecked;
    const priceTargets = this.priceTargets;
    const qtyTargets = this.quantityTargets;

    const monthlyPriceTargets = this.monthlyPriceTargets;
    const monthlyQtyTargets = this.monthlyQuantityTargets;

    this.updateInitialValue(priceTargets, targetId);
    this.updateInitialValue(monthlyPriceTargets, targetId);
    if (initialChecked == "true") {
      this.updateInitialValue(qtyTargets, targetId);
      this.updateInitialValue(monthlyQtyTargets, targetId);
    } else {
      this.updateFiledValue(qtyTargets, targetId, 0);
      this.updateFiledValue(monthlyQtyTargets, targetId, 0)
    }
  }

  updateRentedInitialValue(targetId, event) {
    const initialChecked = event.target.dataset.initialChecked;
    const monthlyPriceTargets = this.monthlyPriceTargets;
    const monthlyQtyTargets = this.monthlyQuantityTargets;

    this.updateInitialValue(monthlyPriceTargets, targetId);
    if (initialChecked == "true") {
      this.updateInitialValue(monthlyQtyTargets, targetId);
    } else {
      this.updateFiledValue(monthlyQtyTargets, targetId, 1)
    }
  }

  updateInitialValue(targets, targetId) {
    targets.forEach(el => {
      if(el.dataset.targetId == targetId) {
        el.value = el.dataset.initialValue;
      }
    })
  }

  updateFiledValue(target, targetId, value) {
    target.forEach(el => {
      if(el.dataset.targetId == targetId) {
        el.value = value;
      }
    })
  }

  hideMonthlyQtyAndPriceContainer(targetId) {
    const monthlyQtyAndPriceContainerTargets = this.monthlyPriceAndQtyInputTargets;
    this.addClass(monthlyQtyAndPriceContainerTargets, targetId, "hidden");
  }

  hideQtyAndPriceContainer(targetId) {
   const qtyAndPriceContainerTarget = this.priceAndQtyInputTargets;
   this.addClass(qtyAndPriceContainerTarget, targetId, "hidden");
  }

  showMonthlyQtyAndPriceContainer(targetId) {
    const monthlyQtyAndPriceContainerTargets = this.monthlyPriceAndQtyInputTargets;
    this.removeClass(monthlyQtyAndPriceContainerTargets, targetId, "hidden");
  }

  showQtyAndPriceContainer(targetId) {
    const qtyAndPriceContainerTarget = this.priceAndQtyInputTargets;
    this.removeClass(qtyAndPriceContainerTarget, targetId, "hidden");
  }

  hideMonthlyLabel(targetId) {
  const monthlyLabelTarget = this.monthlyLabelTargets;
  this.addClass(monthlyLabelTarget, targetId, "hidden")
  }

  hideRegularLabel(targetId) {
  const regularLabelTarget = this.regularLabelTargets;
  this.addClass(regularLabelTarget, targetId, "hidden")
  }

  showMonthlyLabel(targetId) {
  const monthlyLabelTarget = this.monthlyLabelTargets;
  this.removeClass(monthlyLabelTarget, targetId, "hidden")
  }

  showRegularLabel(targetId) {
  const regularLabelTarget = this.regularLabelTargets;
  this.removeClass(regularLabelTarget, targetId, "hidden")
  }

  addClass(target, targetId, classToBeAdded) {
    target.forEach(el => {
      if(el.dataset.targetId == targetId) {
        el.classList.add(classToBeAdded);
      }
    })
  }

  removeClass(target, targetId, classToBeRemoved) {
    target.forEach(el => {
      if(el.dataset.targetId == targetId) {
        el.classList.remove(classToBeRemoved);
      }
    })
  }

  addAttribute(target, targetId) {
    target.forEach(el => {
      if(el.dataset.targetId == targetId) {
        el.setAttribute('disabled', 'disabled')
      }
    })
  }

  removeAttributeAndUpdateValue(target, targetId, attribute) {
    target.forEach(el => {
      if(el.dataset.targetId == targetId) {
        el.removeAttribute('disabled')
        if(el.checked)
          this.updateFiledValue(this.refilledFieldTargets, targetId, true);
        else
        this.updateFiledValue(this.refilledFieldTargets, targetId, false);
      }
    })
  }


  toggleArrowTargets(event) {
    const targetId = event.target.dataset.targetId;
    this.divHideAbleTargets.forEach(el => {
      if (el.dataset.targetId == targetId) {
        el.hidden = !el.hidden
        if (el.hidden) {
          el.classList.add('hidden')
          this.showArrow(this.upArrowTargets, targetId);
          this.hideArrow(this.downArrowTargets, targetId);
        } else {
          el.classList.remove('hidden')
          this.hideArrow(this.upArrowTargets, targetId);
          this.showArrow(this.downArrowTargets, targetId);
        }
      }
    })
  }

  hideArrow(target, targetId) {
    target.forEach(el => {
      if(el.dataset.targetId == targetId) {
        el.classList.add('hidden');
      }
    })
  }

  showArrow(target, targetId){
    target.forEach(el => {
      if(el.dataset.targetId == targetId) {
        el.classList.remove('hidden');
      }
    })
  }

  incPrice(event){
    const targetId = event.target.dataset.targetId;
    const priceTargets = this.priceTargets;
    const gstPercentage = parseFloat(event.target.dataset.gstPercentage);
    const gstEnabled =event.target.dataset.gstEnabled;
    this.incValue(priceTargets, targetId)
    this.showProductPrice(targetId, 'unitPrice', gstPercentage, gstEnabled)
  }

  decPrice(event){
    const targetId = event.target.dataset.targetId;
    const priceTargets = this.priceTargets;
    const gstPercentage = parseFloat(event.target.dataset.gstPercentage);
    const gstEnabled =event.target.dataset.gstEnabled;
    this.decValue(priceTargets, targetId);
    this.showProductPrice(targetId, 'unitPrice', gstPercentage, gstEnabled)
  }

  incQty(event){
    const targetId = event.target.dataset.targetId;
    const qtyTargets = this.quantityTargets;
    const gstPercentage = parseFloat(event.target.dataset.gstPercentage);
    const gstEnabled =event.target.dataset.gstEnabled;
    this.incValue(qtyTargets, targetId)
    this.showProductPrice(targetId, 'unitPrice', gstPercentage, gstEnabled)
  }

  decQty(event){
    const targetId = event.target.dataset.targetId;
    const qtyTargets = this.quantityTargets;
    const gstPercentage = parseFloat(event.target.dataset.gstPercentage);
    const gstEnabled =event.target.dataset.gstEnabled;
    this.decValue(qtyTargets, targetId)
    this.showProductPrice(targetId, 'unitPrice', gstPercentage, gstEnabled)
  }

  incMonthlyPrice(event){
    const targetId = event.target.dataset.targetId;
    const monthlyPriceTargets = this.monthlyPriceTargets;
    const gstPercentage = parseFloat(event.target.dataset.gstPercentage);
    const gstEnabled =event.target.dataset.gstEnabled;
    const isMonthly = event.target.dataset.isMonthly;
    this.incValue(monthlyPriceTargets, targetId)
    this.showProductPrice(targetId, 'monthlyPrice', gstPercentage, gstEnabled, isMonthly)
  }

  decMonthlyPrice(event){
    const targetId = event.target.dataset.targetId;
    const monthlyPriceTargets = this.monthlyPriceTargets;
    const gstPercentage = parseFloat(event.target.dataset.gstPercentage);
    const gstEnabled =event.target.dataset.gstEnabled;
    const isMonthly = event.target.dataset.isMonthly;
    this.decValue(monthlyPriceTargets, targetId)
    this.showProductPrice(targetId, 'monthlyPrice', gstPercentage, gstEnabled, isMonthly)
  }

  incMonthlyQty(event){
    const targetId = event.target.dataset.targetId;
    const monthlyQtyTargets = this.monthlyQuantityTargets;
    const gstPercentage = parseFloat(event.target.dataset.gstPercentage);
    const gstEnabled =event.target.dataset.gstEnabled;
    const isMonthly = event.target.dataset.isMonthly;
    this.incValue(monthlyQtyTargets, targetId)
    this.showProductPrice(targetId, 'monthlyPrice', gstPercentage, gstEnabled, isMonthly)
  }

  decMonthlyQty(event){
    const targetId = event.target.dataset.targetId;
    const monthlyQtyTargets = this.monthlyQuantityTargets;
    const gstPercentage = parseFloat(event.target.dataset.gstPercentage);
    const gstEnabled =event.target.dataset.gstEnabled;
    const isMonthly = event.target.dataset.isMonthly;
    this.decValue(monthlyQtyTargets, targetId)
    this.showProductPrice(targetId, 'monthlyPrice', gstPercentage, gstEnabled, isMonthly)
  }

  incValue(target, targetId){
    target.forEach(el => {
      if(el.dataset.targetId == targetId) {
        if(el.dataset.isPriceField == 'true') {
          el.value = (parseFloat(el.value)+1).toFixed(1);
        } else {
          el.value = parseInt(el.value)+1;
        }
      }
    })
  }

  decValue(target, targetId){
    target.forEach(el => {
      if(el.dataset.targetId == targetId && parseInt(el.value)>0) {
        if(el.dataset.isPriceField == 'true') {
          el.value = (parseFloat(el.value)-1).toFixed(1);
        } else {
          el.value = parseInt(el.value)-1;
        }
      }
    })
  }

  validate(event) {
    let daysOfWeek = this.dayOfWeekTargets;
    this.weekdaysButtonTargets.forEach(el => {
      const targetId = el.dataset.targetId;
      let days = [];
      daysOfWeek.forEach(day=>{
        if(day.dataset.targetId == targetId) {
          days.push(day);
        }
      })
      if(el.checked)
        if (days.some(element => element.checked) == false) {
          this.notifyCustomer(targetId);
          event.preventDefault();
        }
    })
  }

  notifyCustomer(targetId) {
    this.dayOfWeekAlertTargets.forEach(el=>{
      if (el.dataset.targetId == targetId) {
        el.innerHTML = "Please select any days"
      }
    })
  }
  showPrice(event){
    const targetId = event.target.dataset.targetId;
    const gstPercentage = parseFloat(event.target.dataset.gstPercentage);
    const gstEnabled =event.target.dataset.gstEnabled;
    const isMonthly = event.target.dataset.isMonthly;
    const labelTarget =['price','quantity'].includes(event.currentTarget.dataset.customerProductTarget) ? 'unitPrice' : 'monthlyPrice'
    this.showProductPrice(targetId, labelTarget, gstPercentage, gstEnabled, isMonthly)
  }

  showProductPrice(targetId, labelTarget, gstPercentage, gstEnabled, isMonthly = 'false') {
    const isUnitPrice = (labelTarget === 'unitPrice');
    const priceTarget = isUnitPrice ? this.priceTargets : this.monthlyPriceTargets;
    const qtyTarget = isUnitPrice ? this.quantityTargets : this.monthlyQuantityTargets;
    const priceLabelTargets = isUnitPrice ? this.unitPriceLabelTargets : this.monthlyPriceLabelTargets;
    const price = parseFloat(this.getValue(priceTarget, targetId));
    const qty = parseFloat(this.getValue(qtyTarget, targetId));
    this.updatePriceLabel(priceLabelTargets, targetId, price, qty, gstPercentage, gstEnabled, isMonthly)
  }

  getValue(targets, targetId) {
    const target = targets.find(el => el.dataset.targetId == targetId);
    return target ? target.value : undefined;
  }

  updatePriceLabel(priceLabelTargets, targetId, price, qty, gstPercentage, gstEnabled, isMonthly) {
    price = isNaN(price) ? 0 : price;
    qty = isNaN(qty) ? 0 : qty;
    priceLabelTargets.forEach(el => {
      if (el.dataset.targetId == targetId) {
        if(isMonthly=='false'){
          if(gstEnabled == 'true' && gstPercentage > 0){
            el.innerHTML = `Price To Customer (₹ ${price * qty} + ₹ ${price * qty * gstPercentage/100} GST) =  ₹ ${((price+(price*gstPercentage/100))*qty).toFixed(2)}`;
          } else {
            el.innerHTML = `Price To Customer (₹ ${price} * ${qty}) = ₹ ${(price * qty).toFixed(2)}`;
          }
        } else {
          if(gstEnabled == 'true' && gstPercentage > 0){
            el.innerHTML = `Price To Customer (₹ ${price} + ₹ ${price * gstPercentage/100} GST) =  ₹ ${((price+(price*gstPercentage/100))).toFixed(2)}`;
          } else {
            el.innerHTML = `Price To Customer = ₹ ${price.toFixed(2)}`;
          }
        }
      }
    });
  }

  updateIsMonthlyDataAttr(targetId, value){
    const updateDataAttr = (elements) => {
      elements.forEach(el => {
        if (el.dataset.targetId == targetId) {
          el.dataset.isMonthly = value;
        }
      });
    };

    [this.monthlyBtnTargets, this.monthlyPriceTargets, this.monthlyQuantityTargets].forEach(updateDataAttr);
  }
}